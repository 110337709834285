// Estate JS


//setup jQuery space where $ can be used
jQuery(document).ready(function( $ ) {

/*
  // Handle Sub Nav Background
  $('#top-menu > .menu-item').hover(menuHoverIn, menuHoverOut);
  function menuHoverIn(event){
    var distanceLeft = $(this).offset().left;
    $('#top-menu > li > .sub-menu > li').css('left', distanceLeft-35);
  }
  function menuHoverOut(event){}

  // Set the top position of sub sub menus so they match sub menus
  var headerHeight = $('#main-header').position().top;
  $('.sub-menu .sub-menu').css('top', headerHeight).css('position', 'fixed');
  var subMenuHeight = $('#top-menu > li > .sub-menu').css('height');
  $('.sub-menu .sub-menu').css('min-height', subMenuHeight);

  // Reset the top position of sub sub menus on hover so it adjusts in case the top header shrunk
  $('#top-menu > li > .sub-menu > li').hover(subMenuHoverIn, subMenuHoverOut);
  function subMenuHoverIn(event){
    var headerHeight = $('#main-header').position().top;
    $('.sub-menu .sub-menu').css('top', headerHeight).css('position', 'fixed');
    var subMenuHeight = $('#top-menu > li > .sub-menu').css('height');
    $('.sub-menu .sub-menu').css('min-height', subMenuHeight);
    var subSubMenuHeight = $('.sub-menu .sub-menu').css('height');
    $(this).parent('.sub-menu').css('min-height', subSubMenuHeight);
  }
  function subMenuHoverOut(event){}
*/


/*
  //estate archive country selector
  $('.estatesMenu li').click(function(){
    $('.estate-box').show();
    $('.estate-box').not('.' + $(this).text()).hide();
  });

  //estate box hover effect
  $('.feature-box').hover(function(){
    $(this).find('.feature-box-content').show();
    $(this).find('.box-feature-header').addClass('dark-overlay');
  },
  function(){
    $(this).find('.feature-box-content').hide();
    $(this).find('.box-feature-header').removeClass('dark-overlay');
  });

  $('.box-feature-header').removeClass('dark-overlay');

  //wine bottle hover effect
  $('.wine-bottle').hover(function(){
    $(this).find('.bottle-hover-content').show();
  },
  function(){
    $(this).find('.bottle-hover-content').hide();
  });

*/


/*
  // Handle Expanded Reviews on Single Wine Page
  $('.read-full-open').click(function(event){
    var accoladeId = $(this).closest('.accolade-square').attr('id');
    $('#' + accoladeId + ' .expanded-review').show();
    $('#' + accoladeId + ' .read-full-open').hide();
    $('#' + accoladeId + ' .read-full-close').click(function(){
      $('#' + accoladeId + ' .expanded-review').hide();
      $('#' + accoladeId + ' .read-full-open').show();
    });
  });


  // Handle Search Form Fields
  // Get Query Vars from URL
  function getQueryVars(){
    var vars = [];
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
      hash = hashes[i].split('=');

      var myObj = {};
      myObj[hash[0]] = hash[1];
      vars.push(myObj);

    }
    return vars;
  }
  var queryVars;

  var queryVarsExist = (window.location.href.indexOf('?') != -1);
  if(queryVarsExist){
    var queryVars = getQueryVars();
  }

  // Bold page nav links when they are clicked
  if(queryVars && queryVars['asset-type']){
    var assetType = queryVars['asset-type'];
    $( "[href='?asset-type=" + assetType + "'" ).parent().addClass('active');
  }else{
    $( "[href='?asset-type='" ).parent().addClass('active');
  }

  // Put past form entires back into the field from the query vars
  if(queryVars){
    //console.log(queryVars);
    var fieldKeys = {};
    queryVars.forEach(function(obj){
      var key = Object.keys(obj)[0];
      let value = obj[key];

      key = decodeURIComponent(key).replace('[]','');
      if(key.substr(0, 1) === '_'){
        key = key.substr(1);
      }
      value = value.replace(/\+/g,' ');      

      if(fieldKeys[key] != null){
        fieldKeys[key].push(value);
        $("#"+key).val(fieldKeys[key]);
      } else{
        fieldKeys[key] = [value]
        $("#"+key).val(fieldKeys[key]);
      }

    });
  }

  // Validate and strip search form inputs before submit
  $("#search-form").submit(function(){
    $(this).find("select, input").each(function(){
      var thisVal = $(this).val();
      if(thisVal=="") {
        $(this).attr("name", "");
      }
    });
  });
*/





  /* John's code */

  // easy hide of mobile global nav
  $('#mobile-nav-toggler .exit').on('click', function(e) {
    $('#mobile-global-nav').addClass('hidden').delay(700).queue(function(){
      $(this).removeClass('hidden').dequeue();
      $(this).find('a').show();
      $(this).find('li').show();
      $(this).find('.sub-menu').hide();
      $(this).find('#back-to-menu').hide();
    });
  });

  // open submenus in mobile global nav 
  $('#main-menu-container-mobile .menu-item-has-children > a').on('click', function(e) {
    e.preventDefault();
    $(this).hide();
    $(this).parent().siblings().hide();
    $('#back-to-menu').show();
    $('.open-sub-menu').removeClass('open-sub-menu');
    $(this).next('ul.sub-menu').show().addClass('open-sub-menu');
  });

  // go back to parent menu in mobile global nav when clicking back to menu button
  $('#back-to-menu').on('click', function(e) {
    e.preventDefault();
    $('#back-to-menu').hide();
    $('#main-menu-container-mobile').find('.sub-menu').hide();
    $('#main-menu-container-mobile').find('a').show();
    $('#main-menu-container-mobile').find('li').show();
  });

  // resize desktop global nav parent submenu if sub-submenu is taller
  $('#main-header .sub-menu .menu-item-has-children').hover(function(e) {
    var parentMenu = $(this).parent();
    var childMenu = $(this).children('.sub-menu');
    var parentHeight = parentMenu.css('height');
    var childHeight = childMenu.css('height');
    if (parentHeight < childHeight) {
      parentMenu.height(childHeight);
    } 
    if (childHeight <= parentHeight) {
      var newParentHeight = 0;
      parentMenu.children().each(function(){
        newParentHeight += $(this).outerHeight(true);
      });
      parentMenu.height(newParentHeight);
    }
  });

  // only show right arrow for secondary menu submenu items that have child submenus
  $('.secondary-menu-desktop').find('.sub-menu').find('.sub-menu').siblings('a').addClass('right-arrow');
  $('.secondary-menu-mobile').find('.sub-menu').siblings('a').addClass('right-arrow');

  // open submenus in mobile secondary menu
  $('.secondary-menu-mobile .menu-item-has-children > a').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).hide();
    $(this).parent().addClass('hidden-parent');
    $(this).parent().siblings().hide();
    $('#secondary-back-to-menu').show().css('display','block');
    $('.open-sub-menu').removeClass('open-sub-menu');
    $(this).next('ul.sub-menu').show().addClass('open-sub-menu');
  });
  
  // go back to parent menu when clicking back to menu button in secondary nav menu
  $('#secondary-back-to-menu').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('#secondary-back-to-menu').hide();
    $('.secondary-menu-mobile').find('.sub-menu').hide();
    $('.secondary-menu-mobile').find('a').show();
    $('.secondary-menu-mobile').find('li').show();
    $('.secondary-menu-mobile .menu-item').removeClass('hidden-parent');
  });

});
